.provider-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-select-info > span {
  cursor: pointer;
}

.navigation-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navigation-container {
  min-width: 5rem;
  line-height: 1.2rem;
  height: 1.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.8rem;
  padding: 0 0.5rem;
  padding-bottom: 0.4rem;
  border-bottom: #ccc solid 0.1rem;
}

.navigation-info {
  font-size: 0.6rem;
  font-weight: bold;
  position: relative;
  top: -0.4rem;
  margin: 0.2rem;
  padding-left: 1.2rem;
  color: #ccc;
}

.navigation-info > span {
  user-select: none;
  cursor: pointer;
}

.navigation-fill {
  flex: 1 1;
}

.navigation-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem;
  padding-right: 1rem;
}

.navigation-user > .admin {
  font-size: 1rem;
  padding-right: 1rem;
}
