#report-view .main-header {
  display: flex;
  flex-direction: row;
}

#report-view > .main-header {
  border-bottom: 0.1rem solid #999;
  margin-bottom: 1rem;
}

#report-view .main-header > h1 {
  flex-grow: 1;
}

#report-view .main-header > .header {
  flex-grow: 1;
}

#report-view .main-header > .header > h1 {
  margin-bottom: 0;
}

#report-view .main-header > .header > h1 + h2 {
  margin-top: 0;
}

#report-view .main-header > .header > h2 {
  font-size: 1rem;
  color: #999;
  margin-bottom: 0;
}

#report-view .header-btns {
  display: flex;
  justify-content: space-between;
}

#report-view .filter-row {
  display: flex;
  margin-bottom: 15px;
}

#report-view .report-date-picker {
  position: relative;
  margin-top: 1em;
  margin-right: 25px;
}

#report-view .search-form {
  margin-top: 1em;
}

#report-view .filter-dropwdown {
  margin-top: 1em;
  width: 200px;
  margin-right: 25px;
}
