.popup-content {
  padding: 0rem !important;
  border-radius: 1rem;
  border: 0 !important;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  border-radius: 1rem 1rem 0 0;
  color: #1a3dc8;
  font-weight: bold;
  background-color: #e8edfb;
  border-bottom: 0.1rem solid #c5d3f5;
  padding: 1rem;
}

.modal-header > i {
  cursor: pointer;
}

.modal-content {
  padding: 1rem;
}

.modal-content > p {
  font-size: 1rem;
}

.modal-confirm {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}
