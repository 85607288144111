.close-dialog {
  position: absolute;
  top: 20px;
  right: 20px;
}

.account-name-input {
  font-size: 1.5rem;
  border-radius: 0.3rem;
  border: 0.1rem solid #ccc;
  padding: 0.5rem;
  width: 90%;
}

.input-label {
  margin-top: 25px;
}
