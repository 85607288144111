#callback .main-header {
  display: flex;
  flex-direction: row;
}

#callback > .main-header {
  border-bottom: 0.1rem solid #999;
  margin-bottom: 1rem;
}

#callback .details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#callback .detail {
  flex: 1 1 50%;
  margin-bottom: 1rem;
}

#callback .detail > svg {
  padding-right: 0.5rem;
}

#callback .section + .section {
  margin-top: 2rem;
}

#callback .main-header > h1 {
  flex-grow: 1;
}

#callback .main-header > .header {
  flex-grow: 1;
}

#callback .main-header > .header > h1 {
  margin-bottom: 0;
}

#callback .main-header > .header > h2 {
  font-size: 1rem;
  color: #999;
  margin-bottom: 0;
}

#callback .main-header > .header > h1 + h2 {
  margin-top: 0;
}

#callback .callback-icon {
  height: 1rem;
  padding-right: 0.5rem;
}
