input:focus {
  outline: none;
}

.login {
  width: 20em;
  height: 20em;
  text-align: center;

  /* Centers Horizontal & Vertical */
  margin: auto;
  position: absolute;
  top:0; bottom:0;
  left:0; right:0;
}

.login-header {
  color: #CCCCCC;
  background-color: #161628;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
}

.login-form {
  background-color: #fff;
  width: 100%;
  /* height: 100%; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px;
}

.form-group {
  padding-bottom: 0.5em;
}

.form-group input {
  padding: 0.175rem 0.175rem 0.0875rem;
  border: 0;
  border-bottom: 2px solid #eee;
  line-height: 1.9;
  width: 80%;
  transition: all 0.28s ease;
  box-shadow: none;
}

.form-group input:focus {
  border-color:#333;
}

.form-group input[type=submit] {
  padding:5px 15px;
  background:#ccc;
  border:0 none;
  cursor:pointer;
}