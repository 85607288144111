.circle-button {
  margin: 1rem 1rem 1rem 1rem;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  cursor: pointer;
  color: white;
  background-color: #3c6cde;
  border-radius: 3rem;
  text-align: center;
}

.circle-button.disabled {
  cursor: not-allowed !important;
  color: black !important;
  background-color: lightgray !important;
}

.circle-button.btn-small {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 2rem;
  margin: 0;
}

.circle-button.red {
  color: red;
  border: solid red 1px;
  background-color: white;
}

.circle-button.red:hover {
  color: white;
  background-color: red;
}

.circle-button.blue-outline {
  color: #3c6cde;
  border: solid #3c6cde 1px;
  background-color: white;
}

.circle-button.blue-outline:hover {
  color: white;
  background-color: #3c6cde;
}
