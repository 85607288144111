#broadcast-group .main-header {
  display: flex;
  flex-direction: row;
}

#broadcast-group > .main-header {
  border-bottom: 0.1rem solid #999;
  margin-bottom: 1rem;
}

#broadcast-group .details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#broadcast-group .detail {
  flex: 1 1 50%;
  margin-bottom: 1rem;
}

#broadcast-group .detail > svg {
  padding-right: 0.5rem;
}

#broadcast-group .section + .section {
  margin-top: 2rem;
}

#broadcast-group .main-header > .header {
  flex-grow: 1;
}

#broadcast-group .main-header > .header > h1 {
  margin-bottom: 0;
}

#broadcast-group .main-header > .header > h2 {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0;
}

#broadcast-group .main-header > .header > h1 + h2 {
  margin-top: 0;
}

.resource-number {
  font-family: "Roboto", sans-serif;
}

.resource-number + .resource-number {
  margin-top: 0.2rem;
}
.resource-number > svg {
  margin-right: 0.5rem;
}

.btn-remove {
  width: 5%;
}
