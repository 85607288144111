#broadcast-group-form .main-header {
  display: flex;
  flex-direction: row;
}

#broadcast-group-form > .main-header {
  border-bottom: 0.1rem solid #999;
  margin-bottom: 1rem;
}

#broadcast-group-form .main-header > h1 {
  flex-grow: 1;
}

#broadcast-group-form .main-header > .header {
  flex-grow: 1;
}

#broadcast-group-form .main-header > .header > h1 {
  margin-bottom: 0;
}

#broadcast-group-form .main-header > .header > h2 {
  font-size: 1rem;
  color: #999;
  margin-bottom: 0;
}

#broadcast-group-form .main-header > .header > h1 + h2 {
  margin-top: 0;
}

#broadcast-group-form .form-actions {
  display: flex;
}

#broadcast-group-form .form-fill {
  display: flex;
  flex-grow: 1;
}

#broadcast-group-form .users {
  margin-left: 2%;
  margin-right: 2%;
}

#broadcast-group-form .form-checkbox {
  margin-left: 2rem;
  margin-bottom: 1rem;
}
