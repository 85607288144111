html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #333;
  font-family: "Montserrat", sans-serif;
}

#root {
  height: 100%;
}

#admin {
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#admin .navigation {
  display: flex;
  flex-direction: row;
  color: white;
  max-height: 5rem;
  background: #666;
}

.brand {
  padding-left: 1rem;
}

.brand > img {
  max-height: 5rem;
}

#admin .navigation-group {
  margin-left: 2rem;
}

#admin .navigation-group + .navigation-group {
  padding-top: 0.3rem;
}

#admin .provider-select > .form-select {
  margin-left: 0.5rem;
  margin-right: 0;
}

#admin .provider-select > .form-select > .form-select-container > select {
  color: white;
  border-color: #ccc;
  background-color: transparent;
}

#admin .provider-select > .form-select > .form-select-info {
  color: #ccc;
  padding-left: 1rem;
}

#admin .provider-select > .form-select > .form-select-container > select > option {
  color: black;
}

#admin .navigation-group-header {
  height: 2rem;
  line-height: 2rem;
  background: #999;
  padding-left: 0.6rem;
  overflow: hidden;
}

#admin .navigation-item {
  height: 2rem;
  line-height: 2rem;
  margin-left: 1.5rem;
  overflow: hidden;
}

#admin .main {
  flex: 1;
  color: black;
  background-color: white;
  padding: 2rem;
  overflow-y: scroll;
}

#admin .main > div {
  margin-bottom: 4rem;
}
