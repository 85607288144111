h2 {
  min-height: 25px;
}
#branding {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
}
#branding img {
  width: 40px;
  height: 40px;
  margin: 0 0.5rem;
}
#branding svg {
  width: 20px;
  height: 20px;
  margin: 0 0.5rem;
}
.modal-footer {
  display: flex;
  justify-content: space-evenly;
  border-top: 0.1rem solid #c5d3f5;
}

.modal-footer > .form-button {
  font-size: 0.75rem;
  height: 0.5rem;
  line-height: 0.5rem;
  width: 8rem;
}
