.brand {
  padding: 0;
  color: #ffffff;
  max-width: 18rem;

  text-align: center;
}

.brand img {
  max-width: 18rem;
}

.brand img.clickable {
  cursor: pointer;
}
