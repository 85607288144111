#user .main-header {
  display: flex;
  flex-direction: row;
}

#user > .main-header {
  border-bottom: 0.1rem solid #999;
  margin-bottom: 1rem;
}

#user .details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#user .detail {
  flex: 1 1 50%;
  margin-bottom: 1rem;
}

#user .detail > svg {
  padding-right: 0.5rem;
}

#user .section + .section {
  margin-top: 2rem;
}

#user .main-header > .header {
  flex-grow: 1;
}

#user .main-header > .header > h1 {
  margin-bottom: 0;
}

#user .main-header > .header > h2 {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0;
}

#user .main-header > .header > h1 + h2 {
  margin-top: 0;
}

.resource-number {
  font-family: 'Roboto', sans-serif;
}

.resource-number + .resource-number {
  margin-top: 0.2rem;
}
.resource-number > svg {
  margin-right: 0.5rem;
}

.tbl-row.room:hover {
  cursor: not-allowed;
}