#user-form .main-header {
  display: flex;
  flex-direction: row;
}

#user-form > .main-header {
  border-bottom: 0.1rem solid #999;
  margin-bottom: 1rem;
}

#user-form .main-header > h1 {
  flex-grow: 1;
}

#user-form .main-header > .header {
  flex-grow: 1;
}

#user-form .main-header > .header > h1 {
  margin-bottom: 0;
}

#user-form .main-header > .header > h2 {
  font-size: 1rem;
  color: #999;
  margin-bottom: 0;
}

#user-form .main-header > .header > h1 + h2 {
  margin-top: 0;
}

#user-form .form-actions {
  display: flex;
}

#user-form .form-fill {
  display: flex;
  flex-grow: 1;
}

#user-form .broadcast-groups {
  margin-left: 2%;
  margin-right: 2%;
}