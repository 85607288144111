.form-button {
  margin: 1rem 1rem 1rem 1rem;
  cursor: pointer;
  padding: 1rem;
  color: white;
  background-color: #3c6cde;
  border-radius: 1rem;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.form-button.btn-small {
  line-height: 1rem;
  border-radius: 0.75rem;
  font-size: 0.875rem;
}

.form-button.btn-white {
  color: #3c6cde;
  background-color: white;
  border: 0.1rem solid #3c6cde;
}

.form-button.btn-white:hover {
  color: white;
  background-color: #3c6cde;
}

.form-button.btn-red {
  color: red;
  background-color: white;
  border: 0.1rem solid red;
}

.form-button.btn-red:hover {
  color: white;
  background-color: red;
}

.form-button-btn > svg {
  margin-right: 0.2rem;
}

.form-button-btn {
  user-select: none;
}

.form-button.disabled {
  cursor: not-allowed !important;
  color: black !important;
  background-color: lightgray !important;
  border: 0.1rem solid black;
}
