#account .main-header {
  display: flex;
  flex-direction: row;
}

#account > .main-header {
  border-bottom: 0.1rem solid #999;
  margin-bottom: 1rem;
}

#account .details {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

#account .detail {
  flex: 1 1 50%;
  margin-bottom: 1rem;
}

#account .detail > svg {
  padding-right: 0.5rem;
}

#account .detail > img {
  padding-right: 0.5rem;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

#account .section + .section {
  margin-top: 2rem;
}

#account .main-header > h1 {
  flex-grow: 1;
}

#account .main-header > .header {
  flex-grow: 1;
}

#account .main-header > .header > h1 {
  margin-bottom: 0;
}

#account .main-header > .header > h2 {
  font-size: 1rem;
  color: #999;
  margin-bottom: 0;
}

#account .main-header > .header > h1 + h2 {
  margin-top: 0;
}

#account .tbl-row.number:hover {
  cursor: not-allowed;
}

.resource-number {
  font-family: "Roboto", sans-serif;
}

.resource-number + .resource-number {
  margin-top: 0.2rem;
}
.resource-number > svg {
  margin-right: 0.5rem;
}

.btn-remove {
  width: 5%;
}
