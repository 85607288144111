.form-input {
  height: 3rem;
  margin-left: 1rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.form-input > .form-input-background {
  height: 100%;
  width: 100%;
  border: none;
}

.form-input > .form-input-container {
  position: relative;
  padding: 1rem;
  background-color: transparent;
  border-radius: 1rem;
  line-height: 1.2rem;
  position: relative;
  top: -4rem;
  width: calc(100% - 2rem);
}

.form-input > .form-input-container > input {
  height: 100%;
  width: 100%;
  color: #3b6adf;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-bottom: rgba(0, 0, 0, 0.3) solid 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.2rem;
}

.form-input.invalid > .form-input-container > input {
  background-color: #f8d7da;
  border-bottom: #f5c6cb solid 0.1rem;
}

.form-input > .form-input-container > input:disabled {
  color: gray;
  border-bottom: gray solid 0.1rem;
}

.form-input > .form-input-info {
  font-size: 0.6rem;
  font-weight: bold;
  position: relative;
  top: -5rem;
  margin: 0.4rem;
  padding-left: 1.2rem;
  color: #8aa4dc;
}

.form-input.invalid > .form-input-info {
  color: #c21c24;
}
