#number-form .main-header {
  display: flex;
  flex-direction: row;
}

#number-form > .main-header {
  border-bottom: 0.1rem solid #999;
  margin-bottom: 1rem;
}

#number-form .main-header > h1 {
  flex-grow: 1;
}

#number-form .main-header > .header {
  flex-grow: 1;
}

#number-form .main-header > .header > h1 {
  margin-bottom: 0;
}

#number-form .main-header > .header > h2 {
  font-size: 1rem;
  color: #999;
  margin-bottom: 0;
}

#number-form .main-header > .header > h1 + h2 {
  margin-top: 0;
}

#number-form .form-actions {
  display: flex;
}

#number-form .form-fill {
  display: flex;
  flex-grow: 1;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-grow {
  flex-grow: 1;
}

.w-10 {
  width: 10%;
}

.error {
  color: red;
}
