#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5em;
  padding: 0 0.5em 0 0.5em;
}

#header .brand {
  display: inherit;
  align-items: inherit;
  font-size: 1.5em;
}

#header .brand img {
  padding-right: .3em;
  max-height: 1.5em;
}

#header .navigation .user {
  font-size: 1.5em;
  padding-right: .5em;
}

#header .navigation .menu {
  cursor: pointer;
}

#header .brand-logo > img {
    height: 64px;
}

#header .navigation .user {
  font-size: 1.5em;
  padding-right: .5em;
}

#header .navigation .menu {
  cursor: pointer;
}
