#account-form .main-header {
  display: flex;
  justify-content: space-between;
}

#account-form > .main-header {
  border-bottom: 0.1rem solid #999;
  margin-bottom: 1rem;
}

#account-form .main-header > h1 {
  flex-grow: 1;
}

#account-form .main-header > .header {
  flex-grow: 1;
}

#account-form .main-header > .header > h1 {
  margin-bottom: 0;
}

#account-form .main-header > .header > h2 {
  font-size: 1rem;
  color: #999;
  margin-bottom: 0;
}

#account-form .main-header > .header > h1 + h2 {
  margin-top: 0;
}

#account-form .main-header > .webex-indicator {
  display: flex;
  align-items: center;
}

#account-form .main-header > .webex-indicator img.modal-trigger {
  width: 64px;
  height: 64px;
  cursor: pointer;
}

#account-form .form-actions {
  display: flex;
}

#account-form .form-fill {
  display: flex;
  flex-grow: 1;
}
